import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const ContentWrapper = styled.div`
  float: left;
  height: calc(100vh - 290px);
  overflow: hidden;
  display: flex;
  position: relative;

  ${MEDIA.PHONE`
    height: auto;
    display: block;
  `};
`;

export const Container = styled.div`
  margin: 40px 30px 0px 70px;
  float: left;
  display: flex;

  ${MEDIA.PHONE`
    margin: 0;
    display: block;
  `};
`;
