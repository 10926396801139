import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  margin: 30px 70px;
  height: 100px;
  width: calc(100% - 140px);
  position: relative;

  ${MEDIA.PHONE`
    margin: 0 0 30px;
    height: auto;
    width: 100%;
  `};
`;

export const Headline = styled.h3`
  font-size: 20px;
  line-height: 30px;
  font-weight: 800;
  margin-bottom: 10px;

  span {
    font-weight: 400;
    opacity: 0.5;
  }
`;

export const Lead = styled.p`
  font-size: 20px;
  line-height: 26px;
  max-width: 400px;
`;
