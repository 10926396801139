import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Page from 'components/page';
import Hero from 'components/hero';
import Carousel from 'components/carousel';

const Index = ({ data }) => {
  return (
    <Page>
      <Hero />
      <Carousel featured={data.cms.posts.edges} />
    </Page>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
  props: PropTypes.any,
};

export const query = graphql`
  query {
    cms {
      posts(where: { status: PUBLISH }) {
        edges {
          node {
            uri
            title
            Custom {
              year
              director
              featured
              coverImage {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`;

export default Index;
