import React from 'react';
import { Container, Headline, Lead } from './hero.css';

const Hero = () => (
  <Container>
    <Headline>
      mátyás erdély <span>h.c.a.</span>
    </Headline>
    <Lead>
      Cinematographer. Member of the Academy of Motion Picture Arts and
      Sciences and the ICG Local 600.
    </Lead>
  </Container>
);

export default Hero;
