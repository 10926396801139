//Modules
import React from 'react';
import ImagesLoaded from 'react-images-loaded';
import ReactIScroll from 'react-iscroll';
const iScroll = typeof window != 'undefined' ? require('iscroll/build/iscroll-probe') : {};
import PropTypes from 'prop-types';

//Components
import Item from './item/item';
import { Desktop, Mobile } from 'components/media';

//Styled
import { Container, ContentWrapper } from './carousel.css';



//Component
const Carousel = ({ featured }) => {
  // Reference
  let refScroll = React.createRef();
  let refItems = React.createRef();

  // Functions
  const imagesLoaded = () => {
    refScroll.current.refresh();
  };
  const itemHover = i => {
    var items = refItems.current.childNodes.length - 1;
    var maxScroll = refScroll.current._iScrollInstance.maxScrollX;

    var item = refItems.current.childNodes[i];
    var itemLeft = item.offsetLeft;
    var itemWidth = item.clientWidth - 100;
    var itemScroll = (itemLeft - itemWidth) * -1;

    if (i == 0) {
      refScroll.current._iScrollInstance.scrollTo(
        0,
        0,
        400,
        iScroll.utils.ease.quadratic
      );
    } else if (i == items) {
      refScroll.current._iScrollInstance.scrollTo(
        maxScroll,
        0,
        400,
        iScroll.utils.ease.quadratic
      );
    } else {
      refScroll.current._iScrollInstance.scrollTo(
        itemScroll,
        0,
        400,
        iScroll.utils.ease.quadratic
      );
    }
  };

  return (
    <>
    <Desktop>
      <ReactIScroll
        iScroll={iScroll}
        ref={refScroll}
        options={{
          mouseWheel: true,
          scrollbars: false,
          scrollX: true,
          scrollY: false,
          probeType: 2,
        }}
      >
        <ContentWrapper>
          <ImagesLoaded done={imagesLoaded}>
            <Container ref={refItems}>
              {featured.map((film, i) => (
                <div onMouseEnter={() => itemHover(i)} key={i}>
                  <Item data={film} />
                </div>
              ))}
            </Container>
          </ImagesLoaded>
        </ContentWrapper>
      </ReactIScroll>
    </Desktop>

    <Mobile>
      <ContentWrapper>
        {featured.map((film, i) => (
          <Item data={film} key={i} />
        ))}
      </ContentWrapper>
    </Mobile>
    </>
  );
};

Carousel.propTypes = {
  featured: PropTypes.arrayOf(PropTypes.object),
};

export default Carousel;
