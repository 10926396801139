import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './item.css';
import { Link } from 'gatsby';

const Item = ({ data }) => {
  return (
    <Container>
      <Link to={`/${data.node.uri}`}>
        <img src={data.node.Custom.coverImage.sourceUrl} alt="" />
        <div className="meta">
          <h2>{data.node.title}</h2>
          <h3>{data.node.Custom.year} - Director : {data.node.Custom.director}</h3>
        </div>
      </Link>
    </Container>
  );
};

Item.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Item;
