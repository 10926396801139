import styled from 'styled-components';
import { color, elevation, transition } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  height: 415px;
  margin-right: 40px;
  position: relative;
  display: inline-block;

  a {
    width: 100%;
    height: 100%;
    display: block;
    color: ${color.black};

    img {
      height: 45vh;
      transition: 0.2s ${transition.hover};
      border-radius: 3px;
    }

    .meta {
      position: absolute;
      top: calc(45vh - 30px);
      left: 30px;
      transition: 0.15s ${transition.move};

      h2 {
        font-size: 50px;
        line-height: 60px;
        font-weight: 800;
        text-transform: uppercase;
      }

      h3 {
        font-size: 18px;
        line-height: 20px;
        text-transform: uppercase;
      }
    }
  }

  &:hover {
    img {
      transform: translateY(-10px);
      box-shadow: ${elevation.large};
    }

    .meta {
      transform: translateY(30px);
    }
  }

  ${MEDIA.PHONE`
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 20px;

    a {
      width: 100%;
      height: 100%;
      color: ${color.black};

      img {
        height: auto;
        width: 100%;
      }
      .meta {
        position: relative;
        top: 0;
        left: 0;

        h2 {
          font-size: 20px;
          line-height: 30px;
        }

        h3 {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &:hover {
      img {
        transform: translateY(0px);
        box-shadow: none;
      }

      .meta {
        transform: translateY(0px);
      }
    }
  `};
`;
